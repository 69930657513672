<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      class="pt-4"
      color="grey lighten-3"
      mini-variant
    >
      <v-avatar
        :size="36"
        class="d-block text-center mx-auto mb-9"
      >
        <img src="https://storage.googleapis.com/ehealth-image-assets/mayo-cropped.png" />
      </v-avatar>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created: function() {
    document.title = "eHealth Explorer"
  },
};
</script>
