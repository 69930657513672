import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/viewer/:token',
    name: 'viewer',
    component: () => import(/* webpackChunkName: "viewer" */ '../views/ViewerView.vue')
  },
  {
    path: "/error/401",
    name: "error",
    component: () => import(/* webpackChunkName: "error401" */ '../views/errors/ErrorUnauthorized.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
